import React from "react";
import Hero from "../components/hero";
import About from "./About";
const HomePage = () => {
  return (
    <div>
      <Hero />
    </div>
  );
};

export default HomePage;
